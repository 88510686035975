function toggleElementDisplay(elementClass) {
  $(elementClass).toggleClass('hidden')
}

function hideElement(elementClass) {
  $(elementClass).addClass('hidden')
}

function populateSearchbar(isCoworking) {
  var locationValue = isCoworking ? $('#search-autocomplete').val() : $('#fto-search-autocomplete').val()
  var location = truncateString(locationValue, 20)
  var capacity = getFilterValues('#min-capacity-input', '#max-capacity-input', formatCapacityValue)
  setLocationandCapacityValues(location, capacity)
  isCoworking ? setWorkspaceValue() : setPriceValues()
}

function updateText(selector, text) {
  $(selector).text(text);
}

function setLocationandCapacityValues(location, capacity) {
  var officeSearch = window.location.href.includes("office-space")
  updateText('.location-text', location || 'All locations');
  updateText('.capacity-text', capacity ? `${capacity} ${officeSearch ? 'desks' : 'people'}` : 'Any size');
}

function setPriceValues() {
  var budget = getFilterValues('#min-price-input', '#max-price-input', formatPriceValue);
  updateText('.price-text', budget || 'Any price');
}

function setWorkspaceValue() {
  var workspace = $('.categories-dropdown').val();
  updateText('.workspace-text', workspace ? toTitleCase(workspace) : 'All workspaces');
}

function saveLocationToSearch(e){
  if (window.location.href.includes("office-space")) {
    $('#fto-search-autocomplete').val(e.target.innerText)
    $('#office_search_location_preference').val(e.target.innerText)
  } else {
    $('#search-autocomplete').val(e.target.innerText)
  }
  $('.autocomplete-results').hide()
  $('#location-field').val($(this).data('locationId'))
}

function getFilterValues(minInput, maxInput, formatString) {
  var minValue = $(minInput).val();
  var maxValue = $(maxInput).val();
  
  if (minValue && maxValue) {
    return `${formatString(minValue)} - ${formatString(maxValue)}`;
  } else if (minValue) {
    return formatString(minValue);
  } else if (maxValue) {
    return formatString(maxValue);
  }
}

function formatCapacityValue(value) {
  return `${value}`;
}

function formatPriceValue(value) {
  return `£${value}`;
}

function clearCapacityValues() {
  $('.max-capacity-input').val('')
  $('.min-capacity-input').val('')
  $('.apply-capacity').trigger("click")
  hideCapacityDropdown()
}

function updateToggle(toggle) {
  var currentValue = toggle.val();
  var newValue = currentValue === 'true' ? 'false' : 'true';
  toggle.val(newValue);
  if (toggle.hasClass('bg-gray-200')) {
    toggleSlideDisplay(toggle, 'bg-gray-200', 'bg-green-600')
  } else {
    toggleSlideDisplay(toggle, 'bg-green-600', 'bg-gray-200')
  }
}

function displayVenueGrid() {
  if (window.location.href.includes("office-space")) {
    $('.venues-view').removeClass('lg:col-span-5').addClass('lg:col-span-8')
  } else {
    $('.venues-view').removeClass('lg:col-span-3').addClass('lg:col-span-6')
  }
  $('.map-view').addClass('lg:hidden')
  $('.view-grid-only').addClass('lg:hidden')
  $('.view-hybrid-grid').removeClass('lg:hidden').addClass('lg:flex')
  $('.coworking-cards-grid').addClass('lg:grid-cols-3 lg:gap-x-8')
  $('.coworking-boost-grid').addClass('lg:grid-cols-2 lg:gap-x-8')
  $('.fto-cards-grid').addClass('lg:grid-cols-2 lg:gap-x-8')
  $('.shortlisted-venues-panel').addClass('lg:w-1/2')
}

function displayVenueCardsAndMap() {
  if (window.location.href.includes("office-space")) {
    $('.venues-view').addClass('lg:col-span-5').removeClass('lg:col-span-8')
  } else {
    $('.venues-view').addClass('lg:col-span-3').removeClass('lg:col-span-6')
  }
  $('.map-view').removeClass('lg:hidden')
  $('.fto-cards-grid').removeClass('lg:grid-cols-2 lg:gap-x-8')
  $('.coworking-cards-grid').removeClass('lg:grid-cols-3 lg:gap-x-8')
  $('.coworking-boost-grid').removeClass('lg:grid-cols-2 lg:gap-x-8')
  $('.view-grid-only').removeClass('lg:hidden')
  $('.view-hybrid').addClass('lg:hidden').removeClass('lg:flex')
  $('.cards-grid').removeClass('lg:hidden')
  $('.map-view').addClass('lg:col-span-3').removeClass('lg:col-span-6')
  $('.view-map-only').removeClass('lg:hidden')
  $('.venue-pagination').removeClass('lg:hidden')
  $('.view-grid-only').removeClass('lg:hidden').addClass('lg:flex')
  $('.view-hybrid-grid').addClass('lg:hidden').removeClass('lg:flex')
  $('.shortlisted-venues-panel').removeClass('lg:w-1/2')
}

function toggleMobileView(isMapView) {
  var viewElements = [
    ['.cards-grid', !isMapView],
    ['.map-view', isMapView],
    ['.view-map-only', !isMapView],
    ['.venue-pagination', !isMapView],
    ['.view-map-mobile', !isMapView],
    ['.view-list-mobile', isMapView],
  ];

  viewElements.forEach(([selector, showElement]) => {
    $(selector).toggleClass('hidden', !showElement);
  });
}

function displayMapFullScreen() {
  $('.cards-grid').addClass('lg:hidden')
  $('.map-view').removeClass('lg:col-span-3').addClass('lg:col-span-6')
  $('.venues-view').removeClass('lg:col-span-3').addClass('lg:col-span-6')
  $('.view-map-only').addClass('lg:hidden')
  $('.venue-pagination').addClass('lg:hidden')
  $('.view-grid-only').addClass('lg:hidden').removeClass('lg:flex')
  $('.view-hybrid-grid').removeClass('lg:hidden').addClass('lg:flex')
  $('.shortlisted-venues-panel').addClass('lg:w-1/2')
}

function updateInputsWithParams(urlParams) {
  var locationParams = urlParams.get('location_name')
  var minCapacityParams = urlParams.get('min_capacity') || 0
  var maxCapacityParams = urlParams.get('max_capacity') 
  var minPriceParams = urlParams.get('min_price')  || 0
  var maxPriceParams = urlParams.get('max_price') 
  if (locationParams) $('#fto-search-autocomplete').val(locationParams)
  if (minCapacityParams || maxCapacityParams) {
    $('#capacity-input').val(`${minCapacityParams} - ${maxCapacityParams} desks`)
  }
  if (minPriceParams || maxPriceParams) {
    $('#price-input').val(`£${minPriceParams} - ${maxPriceParams} (per month)`)
  }
}

function toggleSlideDisplay(toggle, removeColor, addColor) {
  var removeTranslate = removeColor === 'bg-gray-200' ? 'translate-x-0' : 'translate-x-5'
  var addTranslate = addColor === 'bg-green-600' ? 'translate-x-5' : 'translate-x-0'
  toggle.removeClass(removeColor).addClass(addColor);
  toggle.find('input').removeClass(removeTranslate).addClass(addTranslate);
}

document.addEventListener('DOMContentLoaded', function() {
  
  var urlParams = new URLSearchParams(window.location.search)
  var userModalWelcomeDismissed = localStorage.getItem('userWelcomeModalDismissed');
  
  if (window.location.href.includes("office-space")) {
    if (urlParams.get('office_search') || urlParams.get('location_name')) {
      updateInputsWithParams(urlParams)
      populateSearchbar(false)
    }
  } else {
    if (userModalWelcomeDismissed) {
      hideElement('#user-welcome-modal')
    }
  }

  $(document).on('click', ".location-result", function(e) {
    saveLocationToSearch(e)
  })

  $('.search-bar').on('click', function() {
    toggleElementDisplay('.search-filters')
  })

  $('.coworking-filters-submit, .office-filters-submit, #clear-filters, .close-search-filters').on('click', function() {
    hideElement('.search-filters')
  })

  $('.coworking-filters-submit').on('click', function() {
    populateSearchbar(true)
  })

  $('.coworking-search-filters').on('keypress', function(e) {
    if(e.key == "Enter") {
      populateSearchbar(true)
      hideElement('.coworking-search-filters')
    }
  })

  $('.office-filters-submit').on('click', function() {
    populateSearchbar(false)
  })

  $('.office-search-filters').on('keypress', function(e) {
    if(e.key == "Enter") {
      populateSearchbar(false)
      $('.office-search-filters').addClass('hidden')
    }
  })

  $(document).on('click', '.more-filters', function (e) {
    $('.filter-options').removeClass('hidden')
    hideElement('.sort-options')
  });

  $(document).on('click', '.close-amenities', function(e) {
    e.stopImmediatePropagation()
    hideElement('.filter-options')
  })

  $(document).on('click', '.sort-by', function (e) {
    toggleElementDisplay('.sort-options')
    hideElement('.filter-options')
  });

  $(document).on('click', '.submit-fto-filters', function(e) {
    $('#fto-navbar-search-submit').click()
    if ($(e.target).hasClass('apply-filters-button')) {
      hideElement('.filter-options')
    }
  })

  $(document).on('click', '.submit-coworking-filters', function(e) {
    $('#coworking-navbar-search-submit').click()
    hideElement('.filter-options')
  })

  $(document).on('click', '.fto-sort-input', function(e) {
    $('#fto-navbar-search-submit').click()
    toggleElementDisplay('.sort-options')
  })

  $(document).on('click', '.coworking-sort-input', function(e) {
    $('#coworking-navbar-search-submit').click()
    toggleElementDisplay('.sort-options')
  })

  $(document).on('click', '.fto-mobile-filters-submit', function(e) {
    $('#fto-navbar-search-submit').click()
  })

  $(document).on('click', '.coworking-mobile-filters-submit', function(e) {
    $('#coworking-navbar-search-submit').click()
  })

  $(document).on('click', '.see-more-amenities', function(e) {
    e.stopImmediatePropagation()
    toggleElementDisplay('.building-amenities')
    hideElement('.see-more-amenities')
  })

  $(document).on('click', '#instant-book-toggle, #free-cancellation-toggle, #free-day-pass-toggle', function (e) {
    e.stopImmediatePropagation()
    updateToggle($(this))
    if (($(this).attr('id')) == 'instant-book-toggle') { $('#coworking-navbar-search-submit').click(); }
  });

  $(document).on('click', '.view-grid-only', function(e) {
    displayVenueGrid()
  })

  $(document).on('click', '.view-hybrid-grid', function(e) {
    displayVenueCardsAndMap()
  })

  $(document).on('click', '.view-map-mobile', function(e) {
    e.stopImmediatePropagation()
    toggleMobileView(true)
  })

  $(document).on('click', '.view-list-mobile', function(e) {
    e.stopImmediatePropagation()
    toggleMobileView(false)
  })

  $('.view-map-only').click(function() {
    displayMapFullScreen()
  })

  $('.show-admin-filters, .hide-admin-filters').on('click', function() {
    $('.show-admin-filters, .hide-admin-filters').toggleClass('hidden');
    $('.admin-filters').toggleClass('hidden');
  });

  $('#close-contact-modal').on('click', function() {
    hideElement('#user-welcome-modal')
    localStorage.setItem('userWelcomeModalDismissed', true);
  })

  $(document).on('change', '.radius-dropdown', function(e) {
    $('#coworking-navbar-search-submit').click()
  })

  $(document).on('change', '.fto-radius-dropdown', function(e) {
    $('#fto-navbar-search-submit').click()
  })

  // Venue Page

  $('.more-filters-venue').click(function (e) {
    toggleElementDisplay('.filter-options')
  });

  $('.capacity').click(function () {
    toggleElementDisplay('.capacity-dropdown')
    hideElement('.filter-options')
  });

  $('.apply-capacity' ).click(function () {
    hideElement('.capacity-dropdown')
  });

  $('.clear-capacity').click(function () {
    clearCapacityValues()
  })
})
