function initMap(lat, lng, venues, searchTerm, officeSearchVenueIds = null) {
  
  var officeSpace = window.location.href.includes("office-space")
  var shortlistPage = window.location.pathname == '/office-search-shortlist'
  var userIsAdmin = checkUserIsAdmin()
  
  var infoWindows = []
  var markers = []

  var mapOptions = {
    center: { lat: parseFloat(lat) || 51.5098347, lng: parseFloat(lng) || -0.085976},
    zoom: 15,
    mapTypeControl: false,
    fullscreenControl: true,
    streetViewControl: false,
    scrollwheel: false,
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT
    },
    styles:
    [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#f5f6f7"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ebeced"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ebeced"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#ebeced"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#DAE6F5"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]
  };

  var map = new google.maps.Map(document.getElementById('map'), mapOptions);

  var customIcon = {
    path: "M3.81360312,22.1974542 C0.0923840931,18.4808631 -1.02277384,12.8882269 0.988371675,8.02861641 C2.99951719,3.16900595 7.74067304,1.77635684e-15 13,1.77635684e-15 C18.259327,1.77635684e-15 23.0004828,3.16900595 25.0116283,8.02861641 C27.0227738,12.8882269 25.9076159,18.4808631 22.1863969,22.1974542 L14.0255254,30.3727696 C13.754315,30.6462132 13.3851318,30.8000213 13,30.8000213 C12.6148682,30.8000213 12.245685,30.6462132 11.9744746,30.3727696 L3.81360312,22.1974542 L3.81360312,22.1974542 Z M13,17.3442634 C15.3931636,17.3442634 17.3332061,15.4042209 17.3332061,13.0110573 C17.3332061,10.6178937 15.3931636,8.67785123 13,8.67785123 C10.6068364,8.67785123 8.66679402,10.6178937 8.66679402,13.0110573 C8.66679402,15.4042209 10.6068364,17.3442634 13,17.3442634 Z",
    fillColor: "#343434",
    fillOpacity: 1,
    scale: 1,
    strokeWeight: 1,
    strokeColor: "#FFFFFF"
  }

  var hoverIcon = {
    path: "M3.81360312,22.1974542 C0.0923840931,18.4808631 -1.02277384,12.8882269 0.988371675,8.02861641 C2.99951719,3.16900595 7.74067304,1.77635684e-15 13,1.77635684e-15 C18.259327,1.77635684e-15 23.0004828,3.16900595 25.0116283,8.02861641 C27.0227738,12.8882269 25.9076159,18.4808631 22.1863969,22.1974542 L14.0255254,30.3727696 C13.754315,30.6462132 13.3851318,30.8000213 13,30.8000213 C12.6148682,30.8000213 12.245685,30.6462132 11.9744746,30.3727696 L3.81360312,22.1974542 L3.81360312,22.1974542 Z M13,17.3442634 C15.3931636,17.3442634 17.3332061,15.4042209 17.3332061,13.0110573 C17.3332061,10.6178937 15.3931636,8.67785123 13,8.67785123 C10.6068364,8.67785123 8.66679402,10.6178937 8.66679402,13.0110573 C8.66679402,15.4042209 10.6068364,17.3442634 13,17.3442634 Z",
    fillColor: "#46c4a0",
    fillOpacity: 1,
    scale: 1.2,
    strokeWeight: 1,
    strokeColor: "#FFFFFF",
  }

  var cardHoverIcon = {
    path: "M3.81360312,22.1974542 C0.0923840931,18.4808631 -1.02277384,12.8882269 0.988371675,8.02861641 C2.99951719,3.16900595 7.74067304,1.77635684e-15 13,1.77635684e-15 C18.259327,1.77635684e-15 23.0004828,3.16900595 25.0116283,8.02861641 C27.0227738,12.8882269 25.9076159,18.4808631 22.1863969,22.1974542 L14.0255254,30.3727696 C13.754315,30.6462132 13.3851318,30.8000213 13,30.8000213 C12.6148682,30.8000213 12.245685,30.6462132 11.9744746,30.3727696 L3.81360312,22.1974542 L3.81360312,22.1974542 Z M13,17.3442634 C15.3931636,17.3442634 17.3332061,15.4042209 17.3332061,13.0110573 C17.3332061,10.6178937 15.3931636,8.67785123 13,8.67785123 C10.6068364,8.67785123 8.66679402,10.6178937 8.66679402,13.0110573 C8.66679402,15.4042209 10.6068364,17.3442634 13,17.3442634 Z",
    fillColor: '#46c4a0',
    fillOpacity: 1,
    scale: 1.2,
    strokeWeight: 1,
    strokeColor: "#FFFFFF",
  }

  var shortlistIcon = {
    path: "M3.81360312,22.1974542 C0.0923840931,18.4808631 -1.02277384,12.8882269 0.988371675,8.02861641 C2.99951719,3.16900595 7.74067304,1.77635684e-15 13,1.77635684e-15 C18.259327,1.77635684e-15 23.0004828,3.16900595 25.0116283,8.02861641 C27.0227738,12.8882269 25.9076159,18.4808631 22.1863969,22.1974542 L14.0255254,30.3727696 C13.754315,30.6462132 13.3851318,30.8000213 13,30.8000213 C12.6148682,30.8000213 12.245685,30.6462132 11.9744746,30.3727696 L3.81360312,22.1974542 L3.81360312,22.1974542 Z M13,17.3442634 C15.3931636,17.3442634 17.3332061,15.4042209 17.3332061,13.0110573 C17.3332061,10.6178937 15.3931636,8.67785123 13,8.67785123 C10.6068364,8.67785123 8.66679402,10.6178937 8.66679402,13.0110573 C8.66679402,15.4042209 10.6068364,17.3442634 13,17.3442634 Z",
    fillColor: '#ef4e68',
    fillOpacity: 1,
    scale: 1.2,
    strokeWeight: 1,
    strokeColor: "#FFFFFF",
  }

  var unavailableIcon = {
    path: "M3.81360312,22.1974542 C0.0923840931,18.4808631 -1.02277384,12.8882269 0.988371675,8.02861641 C2.99951719,3.16900595 7.74067304,1.77635684e-15 13,1.77635684e-15 C18.259327,1.77635684e-15 23.0004828,3.16900595 25.0116283,8.02861641 C27.0227738,12.8882269 25.9076159,18.4808631 22.1863969,22.1974542 L14.0255254,30.3727696 C13.754315,30.6462132 13.3851318,30.8000213 13,30.8000213 C12.6148682,30.8000213 12.245685,30.6462132 11.9744746,30.3727696 L3.81360312,22.1974542 L3.81360312,22.1974542 Z M13,17.3442634 C15.3931636,17.3442634 17.3332061,15.4042209 17.3332061,13.0110573 C17.3332061,10.6178937 15.3931636,8.67785123 13,8.67785123 C10.6068364,8.67785123 8.66679402,10.6178937 8.66679402,13.0110573 C8.66679402,15.4042209 10.6068364,17.3442634 13,17.3442634 Z",
    fillColor: '#E1E7EA',
    fillOpacity: 1,
    scale: 1.2,
    strokeWeight: 1,
    strokeColor: "#FFFFFF",
  }

  var allOfficesUnavailableIcon = {
    path: "M3.81360312,22.1974542 C0.0923840931,18.4808631 -1.02277384,12.8882269 0.988371675,8.02861641 C2.99951719,3.16900595 7.74067304,1.77635684e-15 13,1.77635684e-15 C18.259327,1.77635684e-15 23.0004828,3.16900595 25.0116283,8.02861641 C27.0227738,12.8882269 25.9076159,18.4808631 22.1863969,22.1974542 L14.0255254,30.3727696 C13.754315,30.6462132 13.3851318,30.8000213 13,30.8000213 C12.6148682,30.8000213 12.245685,30.6462132 11.9744746,30.3727696 L3.81360312,22.1974542 L3.81360312,22.1974542 Z M13,17.3442634 C15.3931636,17.3442634 17.3332061,15.4042209 17.3332061,13.0110573 C17.3332061,10.6178937 15.3931636,8.67785123 13,8.67785123 C10.6068364,8.67785123 8.66679402,10.6178937 8.66679402,13.0110573 C8.66679402,15.4042209 10.6068364,17.3442634 13,17.3442634 Z",
    fillColor: '#f5bfbf',
    fillOpacity: 1,
    scale: 1.2,
    strokeWeight: 1,
    strokeColor: "#FFFFFF",
  }

  if (venues) {
    for (var i = 0; i < venues.length; i++) {
      createMarker(i)
    }
  }

  function venueIsShortlistedOrInPack(venueId) {
   return (venueIsShortlisted(venueId) || venueAlreadyInPack(venueId))
  }

  function venueIsShortlisted(venueId) {
    var shortlisted = getShortlistedVenues()
    return Array.isArray(shortlisted) ? shortlisted.includes(venueId) : shortlisted == venueId 
  }

  function venueAlreadyInPack(venueId) {
    if (officeSearchVenueIds) {
      return Array.isArray(officeSearchVenueIds) ? officeSearchVenueIds.includes(venueId) : officeSearchVenueIds == venueId 
    }
  }

  function officeIsLive(venueIndex) {
    return venues[venueIndex]['status'] == 'live'
  }

  function allOfficesUnavailable(venueIndex) {
    return venues[venueIndex]['all_offices_unavailable']
  }

  function getVenueData(element) {
    const {
      venueId,
      venueName,
      venueArea,
      venuePostcode,
      venueCoverUrl,
      lowestPricePerMonth,
      venuePageUrl
    } = $(element).data();
  
    return {
      venueId,
      venueName,
      venueArea,
      venuePostcode,
      venueCoverUrl,
      lowestPricePerMonth,
      venuePageUrl
    };
  }

  function createMarker(i) {
    if (officeSpace) {
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(venues[i].latitude, venues[i].longitude),
        map: map,
        venueId: venues[i]['id'],
        title: officeIsLive(i) ? venues[i]['name'] + ' | ' + venues[i]['operator_name'] : '',
        icon: venueIsShortlistedOrInPack(venues[i]['id']) ? shortlistIcon : allOfficesUnavailable(i) ? allOfficesUnavailableIcon : officeIsLive(i) ? customIcon : unavailableIcon,
        url: `/office-space/${venues[i]['city']}/${venues[i]['slug']}`
      });
    } else {
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(venues[i].latitude, venues[i].longitude),
        map: map,
        venueId: venues[i]['id'],
        title: venues[i]['name'],
        icon: customIcon,
        url: "/venues/" + venues[i]['slug']
      });
    }

    markers.push(marker)

    google.maps.event.addListener(marker, 'mouseover', function() {
      handleMarkerHover(marker, true)        
    })

    google.maps.event.addListener(marker, 'mouseout', function() {
      handleMarkerHover(marker, false)
    });

    function handleMarkerHover(marker, hover) {
      if ((!officeSpace || (!venueIsShortlistedOrInPack(marker.venueId) && officeIsLive(i)) && !allOfficesUnavailable(i) )) {
        marker.setIcon(hover ? hoverIcon : customIcon);
      }
    }

    var venueCard = document.getElementById(`venue-card-${marker.venueId}`)
    if(venueCard) {
      venueCard.addEventListener('mouseover', function() {
        marker.setIcon(cardHoverIcon)
      })
      venueCard.addEventListener('mouseout', function() {
        var icon = ((!officeSpace ? customIcon : venueIsShortlistedOrInPack(marker['venueId']) ? shortlistIcon : allOfficesUnavailable(i) ? allOfficesUnavailableIcon : officeIsLive(i) ? customIcon : unavailableIcon))
        marker.setIcon(icon)
      })
    }

    var addToShortlistButton = document.getElementById(`add-to-shortlist-button-${marker.venueId}`)
    if(addToShortlistButton) {
      addToShortlistButton.addEventListener('click', function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        const venueData = getVenueData(this);
        $(this).toggleClass('hidden')
        $(this).siblings().toggleClass('hidden')
        saveVenueToShortlist(venueData.venueId)
        addVenueToListAndUpdateMarker(venueData.venueId, venueData.venueName, venueData.venueArea, venueData.venuePostcode, venueData.venueCoverUrl, venueData.lowestPricePerMonth, venueData.venuePageUrl)
        toggleShortlistForm()
      })
    }

    var removeFromShortlistButton = document.getElementById(`remove-from-shortlist-button-${marker.venueId}`)
    if(removeFromShortlistButton) {
      removeFromShortlistButton.addEventListener('click', function(e) {
        e.preventDefault();
        e.stopImmediatePropagation()
        const venueData = getVenueData(this);
        $(this).toggleClass('hidden')
        $(this).siblings().toggleClass('hidden')
        removeVenueFromShortlist(venueData.venueId)
        removeVenueFromListAndUpdateMarker(venueData.venueId, venueData.venueName, venueData.venueArea, venueData.venuePostcode, venueData.venueCoverUrl, venueData.lowestPricePerMonth, venueData.venuePageUrl, $(this))
        toggleShortlistForm()
      })
    }

    $(document).on('click', `#remove-from-shortlist-sidebar-${marker.venueId}`, function (e) {
      e.stopImmediatePropagation()
      const venueData = getVenueData(this);
      removeVenueFromShortlist(venueData.venueId)
      removeVenueFromShortlistSidebar(venueData.venueId, venueData.venueName, venueData.venueArea, venueData.venuePostcode, venueData.venueCoverUrl, venueData.lowestPricePerMonth, venueData.venuePageUrl)
      $(`#remove-from-shortlist-button-${venueData.venueId}`).toggleClass('hidden')
      $(`#add-to-shortlist-button-${venueData.venueId}`).toggleClass('hidden')
      if (marker['venueId'] == venueData.venueId) {
        marker.setIcon(customIcon)
      }
      var venueIds = getShortlistedVenues()
      if (venueIds.length < 1) {
        $('#office-search-form').addClass('hidden')
      }
    })

    function saveVenueToShortlist(venueId) {
      if (document.cookie.split(";").some((item) => item.trim().startsWith("shortlisted_venue_ids="))) {
        var alreadyShortlisted = getShortlistedVenues()
        var shortlistedArray = Array.isArray(alreadyShortlisted) ? alreadyShortlisted : [alreadyShortlisted]
        if (!shortlistedArray.includes(venueId)) {
          shortlistedArray.push(venueId)
        }
        document.cookie = `shortlisted_venue_ids=${JSON.stringify(shortlistedArray)}`
        $('#shortlisted-venues-count').text(`${shortlistedArray.length} Venues Shortlisted`)
        $('.shortlisted-navbar-count').text(`${shortlistedArray.length}`)
        toggleCreatePackButtonAndShortlistCount(shortlistedArray)
      } else {
        document.cookie = `shortlisted_venue_ids=${venueId}`
        $('.shortlisted-navbar-count').removeClass('hidden')
        $('.shortlisted-navbar-count').text('1')
        $('#shortlisted-venues-count').text('1 Venue Shortlisted')
        $('#create-pack-button').removeClass('hidden')
      }
      $(".shortlisted-venues-panel").removeClass('hidden')
    }

    function removeVenueFromShortlist(venueId) {
      var updatedShortlist = $.grep(getShortlistedVenues(), function(value) {
        return value != venueId;
      });
      $('#shortlisted-venues-count').text(`${updatedShortlist.length} Venues Shortlisted`)
      $('.shortlisted-navbar-count').text(`${updatedShortlist.length}`)
      document.cookie = `shortlisted_venue_ids=${JSON.stringify(updatedShortlist)}`
      toggleCreatePackButtonAndShortlistCount(updatedShortlist)
    }

    function addVenueNameToReviewList(venueName) {
      var name = reformatVenueName(venueName)
      var item = $('<li class="text-sm text-yellow-900 mt-2">New list item</li>').text(name)
      $('#venue-names-list').append(item)
    }

    function removeVenueNameFromReviewList(venueName) {
      var name = reformatVenueName(venueName)
      $('#venue-names-list li').filter(function() {
        return ($(this).text()).trim() === name
      }).remove()
    }

    function addVenueToShortlistSidebar(venueId, venueName, venueArea, venuePostcode, venueCoverUrl, lowestPricePerMonth, venuePageUrl) {
      var name = reformatVenueName(venueName)
      var item = buildShortlistCard(venueId, name, venueArea, venuePostcode, venueCoverUrl, lowestPricePerMonth, venuePageUrl)
      $('#venue-sidebar-list').append(item)
    }

    function removeVenueFromShortlistSidebar(venueId, venueName, venueArea, venuePostcode, venueCoverUrl, lowestPricePerMonth, venuePageUrl) {
      var name = reformatVenueName(venueName)
      var item = buildShortlistCard(venueId, name, venueArea, venuePostcode, venueCoverUrl, lowestPricePerMonth, venuePageUrl)

      $('#venue-sidebar-list li').filter(function() {
        return item.attr('id') === $(this).attr('id')
      }).remove();
    }

    function reformatVenueName(venueName) {
      var nameWithSpace = venueName.replace(/-/g, ' ')
      var nameArray = nameWithSpace.split(' ');
      for (var i = 0; i < nameArray.length; i++) {
        nameArray[i] = nameArray[i].charAt(0).toUpperCase() + nameArray[i].slice(1);
      }
      return nameArray.join(' ')
    }

    function removeVenueFromListAndUpdateMarker(venueId, venueName, venueArea, venuePostcode, venueCoverUrl, lowestPricePerMonth, venuePageUrl, element) {
      if (marker['venueId'] == venueId) {
        if (userIsAdmin) {
          removeVenueNameFromReviewList(element.data('venue-name'))
        } else {
          removeVenueFromShortlistSidebar(venueId, venueName, venueArea, venuePostcode, venueCoverUrl, lowestPricePerMonth, venuePageUrl)
        }
        marker.setIcon(customIcon)
      }
    }

    function addVenueToListAndUpdateMarker(venueId, venueName, venueArea, venuePostcode, venueCoverUrl, lowestPricePerMonth, venuePageUrl) {
      if (marker['venueId'] == venueId) {
        if (userIsAdmin) {
          addVenueNameToReviewList(venueName)
        } else {
          addVenueToShortlistSidebar(venueId, venueName, venueArea, venuePostcode, venueCoverUrl, lowestPricePerMonth, venuePageUrl)
        }
        marker.setIcon(shortlistIcon)
      }
    }

    function toggleShortlistForm() {
      var venueIds = getShortlistedVenues()
      if (venueIds.length < 1) {
        $('#office-search-form').addClass('hidden')
      } else {
        $('#office-search-form').removeClass('hidden')
      }
    }

    function toggleCreatePackButtonAndShortlistCount(shortlistArray) {
      if (shortlistArray.length < 1) {
        $('#create-pack-button').addClass('hidden')
        $('.shortlisted-navbar-count').addClass('hidden')
      } else {
        $('#create-pack-button').removeClass('hidden')
        $('.shortlisted-navbar-count').removeClass('hidden')
      }
    }

    function buildShortlistCard(id, name, area, postCode, coverUrl, lowestPricePerMonth, venuePageUrl) {
      return $(`
        <li class="flex w-full transform text-left text-base transition my-4 md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl" id="shortlist-sidebar-card-${id}">
          <div class="relative flex w-full items-center overflow-hidden bg-white-0 p-4 shadow-2xl sm:px-6 sm:pt-8 md:p-4">
            <button class="remove-from-shortlist-sidebar-button absolute right-4 top-4 text-gray-400 hover:text-gray-500" data-venue-area="${area}" data-venue-cover-url="${coverUrl}" data-venue-id="${id}" data-venue-name="${name}" data-venue-postcode="${postCode}" data-lowest-price-per-month="${lowestPricePerMonth}" id="remove-from-shortlist-sidebar-${id}" type="button">
              <span class="sr-only">Close</span>
              <svg aria-hidden="true" class="h-4 w-4" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </button>
            <div class="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8 items-center">
              <div class="hidden md:block md:col-span-4">
                <img loading="lazy" class="object-cover object-center w-32" alt="Thumbnail of Runway East Soho" src=${coverUrl}>
              </div>
              <div class="sm:col-span-8">
                <h2 class="font-bold text-gray-900 sm:pr-12 underline"><a target="_blank" href=${venuePageUrl}>${name}</a></h2>
                <p class="text-sm text-gray-700 py-1">${area}, ${postCode}</p>
                <p class="text-xs text-gray-700 italic py-1">Offices from £${lowestPricePerMonth} + VAT per month</p>
              </div>
            </div>
          </div>
        </li>`
      )
    }

    function getMarkerAttributes(urlPath, venueId) {
      return $.ajax({
        type: "GET",
        async: false,
        dataType: 'JSON',
        url: `/${urlPath}?venue_id=${venueId}`
      }).responseJSON;
    }

    google.maps.event.addListener(marker, 'click', function() {
      if (officeSpace && !userIsAdmin && !officeIsLive(i)) {
        $('#user-contact-modal').removeClass('hidden')
        return
      }
      if (officeSpace) {
        var venue_marker_attributes = getMarkerAttributes("full_time_office_marker_attributes", venues[i]['id'])
        cover_image_url = venue_marker_attributes['url']
        var shortlisted = venueIsShortlisted(venues[i]['id'])
        var alreadyInPack = venueAlreadyInPack(venues[i]['id'])
        var name_hyphenated = venues[i]['name'].replace(/\s+/g, '-').toLowerCase()
        var area = venues[i]['area'] || venues[i]['city']
        var postCode = venues[i]['post_code']
        var lowest_price = venue_marker_attributes['lowestPrice']
        var venue_page_url = venue_marker_attributes['venuePageUrl']
        var contentString = 
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          '<h3 id="firstHeading" class="font-medium text-2xl">' + venues[i]['name'] + '</h3>' +
          '<p class=" font-medium text-lg text-blue-500">' + venues[i]['operator_name'] + '</p>' +
          '<div id="bodyContent" class="w-60 flex flex-col p-4">' +
          '<img class="w-full" src=' + cover_image_url + ' alt='+ venues[i]['name']  +'>' + 
          `<p class="mt-4 text-gray-200">${area || ''} ${postCode}</p>` +
          `<div class="flex items-baseline justify-between">` +
          `<a href='/${venue_page_url}' target="_blank" class="mt-4 underline">` +
          `See more ></a> ` +
          `<div class="flex flex-col">` +
          `${ !shortlistPage ? `<div class="flex">` +
            `<a class="font-bold flex flex-row ${shortlisted || alreadyInPack ? 'hover:cursor-auto' : 'hover:cursor-pointer'} ${alreadyInPack ? '' : 'add-to-shortlist'}" data-venue-name="${name_hyphenated}" data-venue-area="${area}" data-venue-id="${venues[i]['id']}" data-venue-cover-url="${cover_image_url}" data-venue-postcode="${postCode}" data-lowest-price-per-month="${lowest_price}" data-venue-page-url="${venue_page_url}"> <p class="mt-2">${shortlisted ? 'Added!' : alreadyInPack ? 'Already in pack!' : 'Add to Shortlist'}</p>` +
              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill=${shortlisted || alreadyInPack ? '#ef4e68' : 'none'} viewBox="0 0 24 24" stroke="#ef4e68">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
              </svg>
            </a>`  : ''}` +
          `</div>` +
          `${shortlisted && !shortlistPage ? `<a href=# class="text-red-500 underline remove-from-shortlist" data-venue-name=` + name_hyphenated + ` data-venue-cover-url=`+ cover_image_url + ` data-venue-area="${area}" data-venue-postcode="${postCode}" data-lowest-price-per-month="${lowest_price}" data-venue-page-url="${venue_page_url}" data-venue-id=` + venues[i]['id'] + '> Remove </a>' : ''}` +
          `</div>` +
          `</div>` +
          "</div>" +
          "</div>";

      } else {
        var coworking_venue_attributes = getMarkerAttributes("coworking_venue_marker_attributes", venues[i]['id'])
        var cover_image_url = coworking_venue_attributes['coverUrl']
        var location = venues[i]['area'] || venues[i]['city'] 
        var contentString = 
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          '<h3 id="firstHeading" class="text-2xl">' + venues[i]['name'] + '</h3>' +
          '<div id="bodyContent" class="w-60 flex flex-col p-4">' +
          '<img class="w-full" src=' + cover_image_url + ' alt='+ venues[i]['name']  +'>' + 
          '<p class="mt-4 text-gray-200">' + venues[i]['category'] + ' in ' + location.trim() +  ', ' + venues[i]['post_code'] + '</p>' +
          `<a href='/${coworking_venue_attributes['venuePageUrl']}' target="_blank" class="mt-4 underline">` +
          `See more ></a> ` +
          "</div>" +
          "</div>";
      }

      var infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      $(document).on('click', '.add-to-shortlist', function(e){
        e.preventDefault();
        const venueData = getVenueData(this)
        saveVenueToShortlist(venueData.venueId)
        $(this).text('Added!')
        $(this).next().attr('fill', '#ef4e68')
        $(`#add-to-shortlist-button-${venueData.venueId}`).addClass('hidden')
        $(`#remove-from-shortlist-button-${venueData.venueId}`).removeClass('hidden')
        addVenueToListAndUpdateMarker(venueData.venueId, venueData.venueName, venueData.venueArea, venueData.venuePostcode, venueData.venueCoverUrl, venueData.lowestPricePerMonth, venueData.venuePageUrl)
        toggleShortlistForm()
        setTimeout(function() {
          infowindow.close();
        }, 1000);
      });

      $(document).on('click', '.remove-from-shortlist', function(e) {
        e.preventDefault();
        const venueData = getVenueData(this)
        removeVenueFromShortlist(venueData.venueId)
        $(this).text('Removed!')
        $(`#add-to-shortlist-button-${venueData.venueId}`).removeClass('hidden')
        $(`#remove-from-shortlist-button-${venueData.venueId}`).addClass('hidden')
        removeVenueFromListAndUpdateMarker(venueData.venueId, venueData.venueName, venueData.venueArea, venueData.venuePostcode, venueData.venueCoverUrl, venueData.lowestPricePerMonth, venueData.venuePageUrl, $(this))
        toggleShortlistForm()
        setTimeout(function() {
          infowindow.close();
        }, 1000);
      })

      infoWindows.forEach(function(infoWindow) {
        infoWindow.close();
      });
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
      infoWindows.push(infowindow)
    });
  }

  // Cluster markers / Radius circle

  var clusterSvg = window.btoa(`
    <svg fill="#94B0F4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity="1" r="70" />
    <circle cx="120" cy="120" opacity=".7" r="90" />
    <circle cx="120" cy="120" opacity=".3" r="110" />
    <circle cx="120" cy="120" opacity=".2" r="130" />
    </svg>`);

  var renderer = {
    render: ({ count, position }) => {
      var clusterMarker = new google.maps.Marker({
        label: { text: String(count), color: "white", fontSize: "12px" },
        icon: {
            url: `data:image/svg+xml;base64,${clusterSvg}`,
            scaledSize: new google.maps.Size(40, 40),
          },
        position,
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      })

      google.maps.event.addListener(clusterMarker, 'mouseover', function() {
        setClusterSize(clusterMarker, 60)
      });
  
      google.maps.event.addListener(clusterMarker, 'mouseout', function() {
        setClusterSize(clusterMarker, 40)
      });

      return clusterMarker
    }
  };
  
  if (!shortlistPage && !(officeSpace && userIsAdmin)) {
    var clusterer = new markerClusterer.MarkerClusterer({markers, map, renderer});
    if (searchTerm) {
      new google.maps.Circle({
        strokeColor: "#343434",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: "#343434",
        fillOpacity: 0.1,
        map,
        center: { lat: parseFloat(lat), lng: parseFloat(lng)},
        radius: 500,
      });
    }
  }

  var venueCards = Array.from(document.getElementsByClassName('venue-card'))

  venueCards.forEach(function(venueCard) {
    venueCard.addEventListener('mouseover', function() {
      var venueId = parseInt(this.dataset.venueId)
      highlightOrResetCluster(venueId, 60)
    })
    venueCard.addEventListener('mouseout', function() {
      var venueId = parseInt(this.dataset.venueId)
      highlightOrResetCluster(venueId, 40);
    });
  })

  function highlightOrResetCluster(venueId, size) {
    if (clusterer) {
      var clusters = clusterer.clusters;
      clusters.forEach(cluster => {
        if (cluster.markers.length > 1) {
          cluster.markers.forEach(marker => {
            if (marker.venueId === venueId) {
              setClusterSize(cluster.marker, size)
            }
          });
        }
      });
    }
  }

  function setClusterSize(clusterMarker, size) {
    clusterMarker.setIcon({
      url: `data:image/svg+xml;base64,${clusterSvg}`,
      scaledSize: new google.maps.Size(size, size)
    });
  }
}

export { initMap }

document.addEventListener("DOMContentLoaded", function() {
  var mapElement = document.querySelector("#map");
  if (mapElement) {
    var startCoordinates = mapElement.dataset.startCoordinates;
    var searchTerm = mapElement.dataset.searchTerm;
    var venues = JSON.parse(mapElement.dataset.venues)
    var officeSearchVenueIds = mapElement.dataset.officeSearchVenueIds && JSON.parse(mapElement.dataset.officeSearchVenueIds)
    var lat = startCoordinates.split(',')[0]
    var lng = startCoordinates.split(',')[1]
    initMap(lat, lng, venues, searchTerm, officeSearchVenueIds || null);
  }
});
